<template>
  <div
    v-if="showing"
    class="fixed inset-0 w-full h-screen flex justify-center bg-semi-75"
  >
    <div class="container mt-250-px mx-auto px-4">
      <div class="flex justify-center">
        <div
          class="relative text-center text-2xl w-full lg:w-4/12 bg-blueGray-200 shadow-lg rounded-lg p-8"
        >
          <slot />
          <div class="w-full mt-6" v-if="closable">
            <form @submit.prevent="close">
              <button
                class="rounded uppercase font-bold bg-blueGray-600 p-2 text-sm text-white"
                type="submit"
                ref="close"
              >
                Close
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showing: {
      required: true,
      type: Boolean
    },
    closable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  updated() {
    this.$refs.close && this.$refs.close.focus();
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
