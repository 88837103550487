import { createStore } from "vuex";

const defaultState = () => {
  return {
    isConnected: false,
    showLoadingModal: false,
    showMessageModal: false,
    showToast: false,
    toastMessage: "",
    messageModalText: "",
    playerName: "",
    roomCode: "",
    game: null,
    gameAnswer: null,
    players: {},
    chameleonID: null,
  };
};
export default createStore({
  state() {
    return defaultState();
  },
  mutations: {
    resetState(state) {
      for (const [key, value] of Object.entries(defaultState())) {
        state[key] = value;
      }
    },
    setIsConnected(state, connected) {
      state.isConnected = connected;
    },
    showLoadingModal(state, show) {
      state.showLoadingModal = show;
    },
    showMessageModal(state, { show, msg }) {
      state.showMessageModal = show;
      state.messageModalText = msg;
    },
    setPlayerName(state, name) {
      state.playerName = name;
    },
    setRoomCode(state, code) {
      state.roomCode = code;
    },
    setGame(state, game) {
      state.game = game;
    },
    setGameAnswer(state, answer) {
      state.gameAnswer = answer;
    },
    setPlayers(state, players) {
      state.players = players;
    },
    setChameleonID(state, id) {
      state.chameleonID = id;
    },
    setGameStarted(state, started) {
      state.gameStarted = started;
    },
    showToast(state, { show, message }) {
      state.showToast = show;
      state.toastMessage = message;
    },
    SOCKET_LOBBYCREATED(state, code) {
      state.roomCode = code.toUpperCase();
    },
    SOCKET_GAMEJOINED(state, data) {
      state.roomCode = data.code;
      state.players = data.players;
      state.playerName = data.playerName;
    },
    SOCKET_STARTGAME(state, data) {
      state.game = data.game;
      state.gameAnswer = data.answer;
      state.chameleonID = data.chameleonID;
    },
    SOCKET_NEWGAME(state, players) {
      state.players = players;
      state.game = null;
      state.gameAnswer = null;
      state.chameleonID = null;
    },
    SOCKET_PLAYERSUPDATED(state, data) {
      // CONNNECTED(id, player), DISCONNECTED(id), CHANGED_NAME(id, name)

      switch (data[0]) {
        case "CONNECTED":
          state.players[data[1]] = data[2];
          break;
        case "DISCONNECTED":
          if (!state.game) {
            delete state.players[data[1]];
          } else {
            state.players[data[1]].disconnected = true;
          }
          break;
        case "CHANGED_NAME":
          state.players[data[1]].name = data[2];
          break;

        default:
          console.log("unhandled event!", data[0]);
          break;
      }
    },
    SOCKET_ERRORMSG(state, data) {
      state.messageModalText = data.message;
      state.showMessageModal = true;
    },
  },
});
