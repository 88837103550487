<template>
  <div id="app">
    <div class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full" :style="`background-image: url('${registerBg2}');`"></div>
    <router-view />
    <toast></toast>
    <modal :showing="!isConnected || showLoadingModal" @close="$store.commit('showLoadingModal', false)"><i class="fas fa-fan text-emerald-600 spin text-5xl"></i></modal>
    <modal :showing="showMessageModal" @close="$store.commit('showMessageModal', { show: false })" :closable="true">{{ messageModalText }}</modal>
  </div>
</template>

<script>
import registerBg2 from "@/assets/img/register_bg_2.png";
import Modal from "@/components/Modal.vue";
import Toast from "@/components/Toast.vue";

export default {
  data() {
    return {
      modalShow: false,
      registerBg2,
    };
  },
  computed: {
    showLoadingModal() {
      return this.$store.state.showLoadingModal;
    },
    showMessageModal() {
      return this.$store.state.showMessageModal;
    },
    messageModalText() {
      return this.$store.state.messageModalText;
    },
    isConnected() {
      return this.$store.state.isConnected;
    },
  },
  components: {
    Modal,
    Toast,
  },
  sockets: {
    connect() {
      this.$store.commit("setIsConnected", true);
    },
    disconnect() {
      this.$store.commit("resetState");
      this.$router.push("/");
      this.$store.commit("SOCKET_ERRORMSG", { message: "Disconnected" });
      this.$store.commit("setIsConnected", false);
    },
    gameReset: function() {
      location.assign(location.origin);
    },
    roomClosing() {
      this.$store.commit("resetState");
      this.$store.commit("setIsConnected", this.$socket.client.connected);
      this.$router.push("/");
      this.$store.commit("showLoadingModal", false);
      this.$store.commit("SOCKET_ERRORMSG", { message: "Room closed" });
    },
    errorMsg(data) {
      if (data.reset) this.$router.push("/");
    },
  },
};
</script>

<style>
html {
  background: #1e293b;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
