<template>
  <div>
    <div class="flex flex-wrap m-8 justify-center">
      <div class="relative w-full mb-2 px-4 text-center">
        <div class="bg-semi-20 px-4 py-4 rounded text-3xl md:text-5xl font-bold uppercase tracking-widest-center text-white">
          {{ roomCode }}
        </div>
      </div>
      <div class="w-full lg:w-8/12 mb-2 lg:mb-0 px-4">
        <div class="relative text-center text-2xl font-semibold mt-1 mb-4 w-full" :class="gameData ? 'text-emerald-300' : 'text-white'">
          <h2>
            {{ gameData ? gameData.category : "Category" }}
          </h2>
        </div>
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700 min-h-500-px md:h-500-px justify-center">
          <div class="rounded-t bg-transparent h-full flex flex-wrap justify-center" :class="gameData ? '' : 'items-center'">
            <div class="relative w-full max-w-full flex-grow flex-1" v-if="gameData">
              <div v-for="(word, i) in gameData.words" v-bind:key="word" class="text-white w-full sm:w-6/12 h-70-px md:w-3/12 md:h-3/12 p-2 inline-flex">
                <div class="absolute text-sm word-num p-1">{{ i + 1 }}</div>
                <div class="table w-full h-full justify-center items-center text-center text-xl">
                  <div class="rounded table-cell align-middle font-semibold" :style="wordColor(i)">
                    {{ word }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!gameData" class="text-white font-bold text-2xl" @click.prevent="startGame">
              <div v-if="activePlayers.length < 2">Waiting for more players<span class="dots">.</span><span class="dots">.</span><span class="dots">.</span></div>
              <div v-if="activePlayers.length >= 2">Start the game!</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Possible chat display -->
      <!-- <div class="w-full xl:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded h-500-px"
        >
          <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <div class="pt-2 pb-6 h-420-px">
                testing
              </div>
              <input
                type="text"
                class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-blueGray-200"
              />
            </div>
          </div>
        </div>
      </div> -->
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative text-center flex font-semibold mb-3 w-full text-sm-80 lg:text-base" :class="gameData ? 'text-emerald-300' : 'text-white'">
          <button
            v-if="!gameData"
            :disabled="activePlayers.length < 2"
            class="bg-red-400 active:bg-red-200 w-full text-white font-bold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            @click.prevent="startGame"
            tabindex="1"
          >
            Start Game
          </button>
          <button
            v-if="!showNewGame && gameData"
            class="bg-green active:bg-red-200 w-6/12 text-white font-bold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            @click.prevent="showChameleon = !showChameleon"
            tabindex="1"
          >
            Show Chameleon
          </button>
          <button
            v-if="!showNewGame && gameData"
            class="bg-blue active:bg-red-200 w-6/12 text-white font-bold ml-3 px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            @click.prevent="showWord = !showWord"
            tabindex="2"
          >
            Show Word
          </button>
          <button
            v-if="showNewGame"
            class="bg-red-400 active:bg-red-200 w-full text-white font-bold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            @click.prevent="newGame"
            tabindex="1"
          >
            New Game
          </button>
        </div>
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded h-500-px overflow-y-scroll">
          <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div class="pb-2 text-blueGray-400 mb-1 text-xs font-semibold">
              Players: {{ activePlayers.length }}, Spectating:
              {{ spectators.length }}
            </div>
            <div class="relative w-full max-w-full flex-grow flex-1" v-for="(player, i) in gamePlayers" v-bind:key="player.name">
              <button
                class="text-gray-700 font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-2 w-full ease-linear transition-all duration-150"
                :style="playerColor(player, i)"
              >
                {{ player.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _filter from "lodash/filter";
import { knuthShuffle } from "knuth-shuffle";

export default {
  name: "lobby",
  beforeCreate() {
    if (!this.$store.state.roomCode) {
      this.$router.push("/");
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.$store.commit("showLoadingModal", false);

      navigator.clipboard.writeText(this.roomCode).then(() => {
        this.$store.commit("showToast", {
          show: true,
          message: "Code copied to clipboard!",
        });
      });
    });
  },
  computed: {
    chameleonID() {
      return this.$store.state.chameleonID;
    },
    gameData() {
      return this.$store.state.game;
    },
    gameStarted() {
      return this.$store.state.game != null;
    },
    gameAnswer() {
      return this.$store.state.gameAnswer;
    },
    spectators() {
      return _filter(this.$store.state.players, function(p) {
        return !p.isActive;
      });
    },
    activePlayers() {
      return _filter(this.$store.state.players, function(p) {
        return p.isActive && !p.disconnected;
      });
    },
    showNewGame() {
      return this.gameStarted && this.showChameleon && this.showWord;
    },
  },
  data() {
    return {
      roomCode: this.$store.state.roomCode,
      showChameleon: false,
      showWord: false,
      gamePlayers: [],
    };
  },
  methods: {
    startGame() {
      this.$socket.client.emit("startGame");
      Object.assign(this.gamePlayers, this.activePlayers);
    },
    newGame() {
      this.showChameleon = false;
      this.showWord = false;
      this.$socket.client.emit("newGame");
    },
    playerColor(player) {
      let color;
      if (this.showChameleon) {
        color = player.id == this.chameleonID ? "#ef9a9a" : "#cfd8dc";
      } else if (this.gameStarted && player.disconnected) {
        color = "#cfd8dc";
      } else {
        color = player.color;
      }
      return `background: ${color}`;
    },
    wordColor(i) {
      let color;
      if (this.showWord && i == this.gameAnswer) {
        color = "#e57373";
      } else {
        color = "#475569";
      }
      return `background: ${color}`;
    },
  },
  watch: {
    activePlayers(newVal) {
      if (!this.gameStarted) {
        this.gamePlayers = newVal;
      }
    },
  },
  sockets: {
    startGame(data) {
      this.$store.commit("setGame", data.game);
      this.$store.commit("setGameAnswer", data.answer);
      knuthShuffle(this.activePlayers);
    },
  },
};
</script>

<style scoped>
.bg-green {
  background: #2dce89;
}
.bg-blue {
  background: #11cdef;
}
.bg-blue:active {
  background: #81d4fa;
}
</style>
