<template>
  <div>
    <div class="container mx-auto px-4 h-full">
      <div class="relative px-2 pt-3 w-full">
        <div class="px-4 pt-4 mx-auto flex flex-wrap items-baseline justify-center">
          <div class="flex">
            <div class="text-white text-6xl font-bold leading-relaxed inline-block whitespace-nowrap uppercase camo">
              Chameleon
            </div>
            <div class="text-white text-6xl font-bold leading-relaxed inline-block whitespace-nowrap uppercase camo">
              Chameleon
            </div>
          </div>
        </div>
      </div>
      <div class="container relative px-4 mx-auto flex flex-wrap items-baseline justify-center">
        <div class="flex pb-3">
          <div class="text-white text-3xl leading-relaxed inline-block py-2 whitespace-nowrap">
            ᘛ⁐̤ᕐᐷ
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="flex-auto px-4 lg:px-10 py-5">
              <form>
                <div class="text-center mb-3">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    @click.prevent="createLobby"
                    tabindex="1"
                  >
                    Create New Lobby
                  </button>
                </div>
              </form>
              <hr class="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div class="flex-auto px-4 lg:px-10 py-5 pt-0">
              <form @submit.prevent="joinLobby">
                <div class="relative w-full mb-3">
                  <input
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 font-bold bg-white rounded text-2xl uppercase tracking-widest-center shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Code"
                    maxlength="4"
                    v-model="code"
                    ref="input"
                    tabindex="2"
                  />
                </div>
                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Join Lobby
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { code: "" };
  },
  methods: {
    createLobby: function() {
      this.$socket.client.emit("createLobby");
      this.$store.commit("showLoadingModal", true);
    },
    joinLobby: function() {
      this.$socket.client.emit("joinGame", this.code);
    },
  },
  sockets: {
    lobbyCreated(data) {
      this.$gtag.event("game_created");

      this.$store.commit("setRoomCode", data);
      this.$router.push("lobby");
    },
    gameJoined() {
      this.$router.push("game");
    },
    disconnect() {
      console.log("socket disconnect");
    },
    reconnect() {
      console.log("socket reconnect");
    },
  },
};
</script>

<style>
.camo {
  font-weight: 900;
  color: #384c6d;
}

.camo:nth-child(2) {
  position: absolute;
  color: #fff;
  clip-path: inset(-1% -1% 50% -1%);
}

.text-6xl {
  font-size: 3.5rem;
  line-height: 1;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}
</style>
