<template>
  <div
    class="fixed top-0 w-full flex justify-center bg-red-400-semi p-1 ease-linear transition-all duration-300"
    :style="`opacity:${toastOpacity}`"
  >
    <div class="font-bold text-white text-sm">{{ toastMessage }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toastOpacity: 0
    };
  },
  computed: {
    showToast() {
      return this.$store.state.showToast;
    },
    toastMessage() {
      return this.$store.state.toastMessage;
    }
  },
  watch: {
    showToast(val) {
      if (val) {
        this.toastOpacity = 1;

        setTimeout(
          function(t) {
            t.toastOpacity = 0;
            setTimeout(
              function(t) {
                t.$store.commit("showToast", false, "");
              },
              200,
              t
            );
          },
          3500,
          this
        );
      }
    }
  }
};
</script>
