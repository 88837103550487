import { createWebHistory, createRouter } from "vue-router";

import Landing from "@/views/Landing.vue";
import Lobby from "@/views/Lobby.vue";
import Game from "@/views/Game.vue";

// routes

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/lobby",
    name: "lobby",
    component: Lobby,
  },
  {
    path: "/game",
    name: "game",
    component: Game,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
