import { createApp } from "vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";
import io from "socket.io-client";
import VueSocketIOExt from "vue-socket.io-extended";

const socket = io(process.env.VUE_APP_SERVER_HOSTNAME);

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

createApp(App)
  .use(router)
  .use(store)
  .use(VueSocketIOExt, socket, { store })
  .use(VueGtag, { config: { id: process.env.VUE_APP_GTAG }, appName: "Chameleon", pageTrackerScreenviewEnabled: true }, router)
  .mount("#app");
