<template>
  <div>
    <div class="container mx-auto px-4 h-full pt-6">
      <div class="relative px-2 pt-3 w-full">
        <div class="px-4 p-4 mx-auto flex flex-wrap items-baseline justify-center">
          <div class="flex">
            <div class="text-white text-3xl uppercase tracking-widest-center font-bold leading-relaxed inline-block whitespace-nowrap">
              {{ code }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="flex-auto px-4 lg:px-10 py-5">
              <form>
                <div class="text-center mb-3 text-3xl font-bold uppercase px-6 py-3">
                  <div v-if="gameAnswer && !isChameleon" class="text-5xl">
                    <span v-if="isActive">{{ gameAnswer + 1 }}</span>
                    <span v-else class="text-2xl">Game In Progress</span>
                  </div>
                  <div v-if="gameAnswer && isChameleon" class="text-2xl">
                    chameleon
                  </div>
                  <div v-if="!gameAnswer">
                    Ready
                    <div><span class="dots">.</span><span class="dots">.</span><span class="dots">.</span></div>
                  </div>
                </div>
              </form>
              <hr class="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div class="flex-auto px-4 lg:px-10 py-5 pt-0">
              <form @submit.prevent="changeName">
                <div class="relative w-full mb-3">
                  <input
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-gray-700 text-center tracking-wide bg-white rounded text-xl shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Player Name"
                    maxlength="20"
                    v-model="playerNameChange"
                    v-if="!gameStarted"
                  />
                  <div
                    v-if="gameStarted"
                    :style="`background:${color}`"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-gray-700 text-center tracking-wide bg-white rounded text-xl shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    {{ playerName }}
                  </div>
                </div>
                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                    :disabled="gameStarted || playerName == playerNameChange"
                    v-if="!gameStarted"
                    tabindex="1"
                  >
                    Change Name
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  beforeCreate() {
    if (!this.$store.state.roomCode) {
      this.$router.push("/");
    }
  },
  data() {
    return {
      playerNameChange: this.$store.state.playerName,
      isChameleon: false,
    };
  },
  computed: {
    playerName() {
      return this.$store.state.playerName;
    },
    code() {
      return this.$store.state.roomCode;
    },
    gameStarted() {
      return this.$store.state.game != null;
    },
    gameAnswer() {
      return this.$store.state.gameAnswer;
    },
    color() {
      if (this.$store.state.players && this.$store.state.players[this.$socket.client.id]) {
        return this.$store.state.players[this.$socket.client.id].color;
      }
      return "white";
    },
    isActive() {
      return this.$store.state.players[this.$socket.client.id].isActive
    },
  },
  methods: {
    changeName() {
      this.$socket.client.emit("changeName", this.playerNameChange);
    },
  },
  sockets: {
    startGame(data) {
      if (this.$socket.client.id == data.chameleonID) this.isChameleon = true;
    },
    newGame() {
      this.isChameleon = false;
    },
    nameChanged(data) {
      if (data.error) {
        this.$store.commit("SOCKET_ERRORMSG", { message: data.error });
      } else {
        this.$store.commit("setPlayerName", data.playerName);
        this.$store.commit("showToast", {
          show: true,
          message: "Successfully changed name!",
        });
      }
    },
  },
};
</script>

<style>
.pt-3 {
  padding-top: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

@keyframes disappear1 {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes disappear2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes disappear3 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dots:nth-child(1) {
  opacity: 1;
  animation: disappear1 1s infinite;
}
.dots:nth-child(2) {
  opacity: 0;
  animation: disappear2 1s infinite;
}
.dots:nth-child(3) {
  opacity: 0;
  animation: disappear3 1s infinite;
}
</style>
